import React, { useEffect, useState, useRef } from "react";
import { useStore } from "../../store";
import Seed from "../Seed";
import { growthStages } from "../../constants";
import GardenGallery from "../GardenGallery/index";
import Video from "../Utils/Video";
import Wrapper from "../Utils/Wrapper";
import config from "../../config.json";
import Image from "../Utils/image";
import { useTranslation } from 'react-i18next';

// == Import
import "./styles.scss";

let modif = true;

// == Composant
const Welken = ({ moveCursorSlogan }) => {
  const { t } = useTranslation();
  const setScrollLocked = useStore((state) => state.setScrollLocked);
  const videoJ = useStore((state) => state.videoJ);
  // const videoK = useStore((state) => state.videoK);

  useEffect(() => {
    if (modif) {
      const welkenLetters = document.querySelectorAll(".welken__letters");
      for (let i1 = 0; i1 < welkenLetters.length; i1++) {
        const text = welkenLetters[i1].innerHTML;
        welkenLetters[i1].innerHTML = "";
        const ending = Number(welkenLetters[i1].dataset.end);
        if (welkenLetters[i1][0] !== "<") {
          for (let i2 = 0; i2 < text.length; i2++) {
            let newSpan = document.createElement("span");
            const randomRotate = Math.random() * 200 - 100;
            newSpan.innerHTML = text[i2] === " " ? "_" : text[i2];
            newSpan.className = text[i2] === " " ? "span--empty" : "";
            newSpan.setAttribute(
              `data-${ending - 500}`,
              "position:relative;transform:rotate(0deg);opacity:1;"
            );
            newSpan.setAttribute(
              `data-${ending}`,
              `position:relative;transform:rotate(${randomRotate}deg);opacity:1;`
            );
            newSpan.setAttribute(
              `data-240000`,
              `position:relative;transform:rotate(${randomRotate}deg);opacity:1;`
            );
            newSpan.setAttribute(
              `data-240200`,
              `position:relative;transform:rotate(${randomRotate}deg);opacity:0;`
            );
            // console.log(newSpan);
            welkenLetters[i1].appendChild(newSpan);
          }
        }
      }
      modif = false;
    }
  }, []);

  useEffect(() => {
    const welkenLetters = document.querySelector(".vermodern__title");
    const text = welkenLetters.innerHTML;
    welkenLetters.innerHTML = "";
    const ending = Number(welkenLetters.dataset.end);
    for (let i2 = 0; i2 < text.length; i2++) {
      let newSpan = document.createElement("span");
      const randomRotate = Math.random() * 200 - 100;
      newSpan.innerHTML = text[i2] === " " ? "_" : text[i2];
      newSpan.className = text[i2] === " " ? "span--empty" : "";
      newSpan.setAttribute(
        `data-${ending}`,
        "position:relative;transform:rotate(0deg)"
      );
      newSpan.setAttribute(
        `data-${ending - 500}`,
        `position:relative;transform:rotate(${randomRotate}deg)`
      );
      // console.log(newSpan);
      welkenLetters.appendChild(newSpan);
    }
  }, []);

  return (
    <section
      className="section section--welken"
      id="welken"
      data-0="display:none;pointer-events:none;position:fixed;top:0;left:0"
      data-200000="display:none;pointer-events:none;position:fixed;top:0;left:0"
      data-200001="display:block;pointer-events:all;position:fixed;top:0;left:0"
      data-250000="display:block;pointer-events:all;position:fixed;top:0;left:0"
      data-250001="display:none;pointer-events:none;position:fixed;top:0;left:0"
      onMouseMove={(event) => moveCursorSlogan(event)}
    >
      <div
        className="welken__title welken__letters"
        data-end="202000"
        data-0="bottom:110vh;left:40vw"
        data-200000="bottom:110vh;left:40vw"
        data-202000="bottom:0vh;left:20vw"
      >
        {t('welken.title')}
      </div>
      <div
        className="welken__decay welken__letters"
        data-end="202500"
        data-0="bottom:110vh;left:40vw"
        data-201000="bottom:110vh;left:40vw"
        data-202500="bottom:0vh;left:70vw"
      >
        {t('welken.decay')}
      </div>
      <div
        className="welken__zyklus welken__letters"
        data-end="203500"
        data-0="bottom:110vh;left:70vw"
        data-202000="bottom:110vh;left:70vw"
        data-203500="bottom:0vh;left:40vw"
      >
        {t('welken.cycle')}
      </div>
      <div
        className="welken__decay welken__letters welken__decay--2"
        data-end="204000"
        data-0="bottom:110vh;left:40vw"
        data-203000="bottom:110vh;left:40vw"
        data-204000="bottom:0vh;left:70vw"
      >
       {t('welken.decay')}
      </div>
      <div
        className="welken__video1"
        data-0="bottom:110vh;left:40vw;animation:blink .3s infinite normal"
        data-202000="bottom:110vh;left:40vw;animation:blink .3s infinite normal"
        data-204000="bottom:0vh;left:-10vw;animation:blink .3s forwards normal"
        data-215000="bottom:0vh;left:-10vw;animation:blink .3s infinite normal"
        data-216000="bottom:-100vh;left:-30vw;animation:blink .3s infinite normal"
      >
        <Wrapper fromStep={5} toStep={8}>
          <Video
            src={
              "https://a.storyblok.com/f/107910/x/237aa56820/welken_1280.mp4"
            }
            poster={
              "https://a.storyblok.com/f/107910/886x500/11ca9d51ed/welken.png"
            }
            playing={videoJ}
            track={"/subtitles/welken_final.vtt"}
          ></Video>
        </Wrapper>
      </div>

      <div
        className="userphoto userphoto--6"
        data-214000="opacity:0;display:none"
        data-215000="opacity:0;display:block"
        data-217000="opacity:1;display:block"
        data-221000="opacity:1;display:block"
        data-221500="opacity:0;display:block"
        data-221501="opacity:0;display:none"
      >
        <div className="userphoto__info userphoto__info--6" />
        <Seed growthStage={growthStages.welken} debug={false}></Seed>
      </div>

      {/* VIDEO/AUDIO only? */}
      <div
        className="welken__eternal welken__letters"
        data-end="224000"
        data-0="bottom:110vh;left:50vw"
        data-222000="bottom:110vh;left:50vw"
        data-224000="bottom:0vh;left:3vw"
      >
        {t('welken.data')}
      </div>

      <div
        className="welken__text1"
        data-0="font-size:0px;opacity:0;width:100vw;height:100vh;left:0vw;top:0vh"
        data-220000="font-size:0px;opacity:0;width:100vw;height:100vh;left:0vw;top:0vh"
        data-220500="font-size:0px;opacity:0.5;width:100vw;height:100vh;left:0vw;top:0vh;"
        data-223500="font-size:200px;opacity:1;width:1000vw;height:1000vh;left:-450vw;top:-450vh;"
        data-224500="font-size:400px;opacity:0;width:1000vw;height:1000vh;left:-450vw;top:-450vh;"
      >
        <p>
        <br />
          {t('welken.text1.a')}
          <br />
          {t('welken.text1.b')}
          <br />
          {t('welken.text1.c')}
          <br />
          {t('welken.text1.d')}
          <br />
          {t('welken.text1.e')}
          <br />
          {t('welken.text1.f')}
        </p>
      </div>
      <div
        className="welken__decay welken__letters"
        data-end="225000"
        data-0="bottom:150vh;left:40vw"
        data-223000="bottom:150vh;left:40vw"
        data-225000="bottom:0vh;left:70vw"
      >
       {t('welken.decay')}
      </div>
      <div
        className="welken__slogan2 welken__letters"
        data-end="226000"
        data-0="bottom:110vh;left:60vw"
        data-224000="bottom:110vh;left:60vw"
        data-226000="bottom:0vh;left:30vw"
      >
       {t('welken.zeit')}
      </div>

      <div
        className="verzweigen__circle"
        data-0="opacity:0"
        data-225000="opacity:0"
        data-225500="opacity:0.8"
        data-236500="opacity:0"
      >
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width="300px"
          height="300px"
          viewBox="0 0 300 300"
          enableBackground="new 0 0 300 300"
          xmlSpace="preserve"
        >
          <defs>
            <path
              id="circlePath1"
              d="M 150, 150 m -60, 0 a 60,60 0 0,1 120,0 a 60,60 0 0,1 -120,0 "
            />
          </defs>
          <circle cx="150" cy="100" r="75" fill="none" />
          <g>
            <use xlinkHref="#circlePath1" fill="none" />
            <text fill="white">
              <textPath xlinkHref="#circlePath1">
              {t('welken.cycle')}
              </textPath>
            </text>
          </g>
        </svg>
      </div>

      <div
        className="welken__decay welken__letters welken__decay--3"
        data-end="229000"
        data-0="bottom:150vh;left:40vw"
        data-226000="bottom:130vh;left:40vw"
        data-229000="bottom:0vh;left:-70vw"
      >
       {t('welken.decay')}
      </div>
      <div
        className="welken__slogan4 welken__letters"
        data-end="230000"
        data-0="bottom:110vh;left:40vw"
        data-227000="bottom:110vh;left:40vw"
        data-230000="bottom:0vh;left:70vw;opacity:1"
        data-240000="opacity:1"
        data-240200="opacity:0"
        onClick={() => {
          if (
            document.querySelector(".verzweigen__circle svg") && document.querySelector(".verzweigen__circle svg").style
              .animationDirection === "reverse"
          ) {
            document.querySelector(
              ".verzweigen__circle svg"
            ).style.animationDirection = "normal";
          } else
            if(document.querySelector(
              ".verzweigen__circle svg"
            )) document.querySelector(
              ".verzweigen__circle svg"
            ).style.animationDirection = "reverse";
        }}
      >
          {t('welken.function')}
      </div>

      <div
        className="welken__eternal welken__eternal--2 welken__letters"
        data-end="231000"
        data-0="bottom:110vh;left:40vw"
        data-230000="bottom:110vh;left:40vw"
        data-232000="bottom:0vh;left:70vw"
      >
       {t('welken.data')}
      </div>
      <div
        className="welken__gallery"
        data-0="display:block;bottom:100vh;left:100vw;width:50vw;pointer-events:none;height:100vh"
        data-231000="display:block;bottom:100vh;left:100vw;width:50vw;pointer-events:none;height:100vh"
        data-235000="display:block;bottom:0vh;left:0vw;width:100vw;pointer-events:none;height:100vh"
        data-235001="display:block;bottom:0vh;left:0vw;width:100vw;pointer-events:all;height:100vh"
        data-235500="display:block;bottom:0vh;left:0vw;width:100vw;pointer-events:all;height:100vh"
        data-236001="display:none;bottom:-100vh;left:-100vw;width:100vw;pointer-events:all;height:100vh"
      >
        <Wrapper fromStep={5} toStep={6}>
          {config.galleryEnabled && <GardenGallery></GardenGallery>}
        </Wrapper>
      </div>
      <div
        className="welken__galleryLeave"
        data-0="display:none;"
        data-235000="display:block;"
        data-236000="display:block;"
        data-236001="display:none;"
        onClick={() => {
          // document.querySelector("html").style.overflow = "scroll";
          setScrollLocked(false);
          window.scrollTo(0, 236002);
          // is this necessary
          setTimeout(() => {
            // document.querySelector("html").style.overflow = "scroll";
            setScrollLocked(false);
          }, 200);
        }}
      >
        <button>{t('vermodern.continue')}</button>
      </div>

      <div
        className="welken__text2"
        data-end="235000"
        data-0="opacity:0"
        data-230000="opacity:0"
        data-234000="opacity:1"
        data-235000="opacity:1"
        data-235010="opacity:0"
      >
        {t('welken.text2.a')}
        <br />{t('welken.text2.b')}
        <br />{t('welken.text2.c')}
        <br />{t('welken.text2.d')}
      </div>

      <div
        className="welken__slogan5 welken__letters"
        data-end="232000"
        data-0="bottom:110vh;left:40vw"
        data-231000="bottom:110vh;left:40vw"
        data-234000="bottom:0vh;left:20vw"
      >
       {t('welken.slogan5')}
      </div>

      {/* AUDIO */}

      <div
        className="welken__slogan6"
        data-235000="opacity:0"
        data-235010="opacity:1"
        data-236000="opacity:0"
      >
         {t('welken.slogan6')}
      </div>
      <div
        className="welken__mouse"
        data-235000="opacity:0"
        data-235010="opacity:1"
        data-236000="opacity:0"
      >
       {t('welken.mouse.a')}
      <br/>{t('welken.mouse.b')}
      <br/>{t('welken.mouse.c')}
      <br/>{t('welken.mouse.d')}
      </div>

      <div
        className="welken__slogan7"
        data-235000="opacity:0"
        data-235010="opacity:1"
        data-236000="opacity:0"
      >
        {t('welken.slogan7')}
      </div>

      {/* TRANSIT TO HYPERCAM */}

      <div
        className="welken__texthc"
        data-235500="left:100vw;top:-30vh"
        data-236500="left:-100vw;top:100vh"
      >
       {t('welken.text3')}
      </div>

      {/* 834cd589-e849-47a0-9bcc-3f842ec7b6e7 — FLOWER BOXES */}
      {/* PETALS 0e3fd13e-9f80-4e88-959c-674b85401feb */}

      <div
        className="welken__floater welken__floater--1"
        data-0="left:30vw;top:-50vh;"
        data-224000="left:30vw;top:-50vh;"
        data-228000="left:40vw;top:100vh;"
      >
        {/* FLOWER BOXES*/}
        <Image id="834cd589-e849-47a0-9bcc-3f842ec7b6e7" />
      </div>
      <div
        className="welken__floater welken__floater--2"
        data-0="left:10vw;top:-100vh;"
        data-228000="left:20vw;top:-100vh;"
        data-231000="left:40vw;top:100vh;"
      >
        {/*PETALS*/}
        <Image id="0e3fd13e-9f80-4e88-959c-674b85401feb" />
      </div>
    </section>
  );
};

// == Export
export default Welken;
