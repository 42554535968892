import React, { useEffect, useState } from "react";
import $ from "jquery";
import { useStore } from "../../store";
import Image from '../Utils/image';
import { useTranslation } from 'react-i18next';

// == Import
import "./styles.scss";

// == Composant
const GardenClosed = () => {

  //  const [remainingTime, setRemainingTime] = useState('');
   const { t } = useTranslation();
   
  // const openingTimes = [
  //   {
  //     date: 21,
  //     opening: 19,
  //     closing: 22
  //   },
  //   {
  //     date: 22,
  //     opening: 16,
  //     closing: 22
  //   },
  //   {
  //     date: 23,
  //     opening: 16,
  //     closing: 22
  //   },
  //   {
  //     date: 24,
  //     opening: 11.5,
  //     closing: 20.5
  //   },
  // ]


  // const calculateTime = () => {
  //   const currentDate = new Date();
  //   const openingTimesConverted = [];

  //   const convertHour = (value) => {
  //     return Math.trunc(value)
  //   }

  //   const convertMinutes = (value) => {
  //     if(Math.trunc(value) !== value) {
  //       return (value - Math.trunc(value)) * 60
  //     }
  //     else return 0
  //   }

  //   for(let i = 0; i < openingTimes.length; i++) {
  //     openingTimesConverted[i] = {
  //       opening: new Date(Date.UTC(2022, 8, openingTimes[i].date, convertHour(openingTimes[i].opening - 2), convertMinutes(openingTimes[i].opening - 2))),
  //       closing: new Date(Date.UTC(2022, 8, openingTimes[i].date, convertHour(openingTimes[i].closing - 2), convertMinutes(openingTimes[i].closing - 2)))
  //     }
  //   }

  //   let nextOpening;
  //   let closed = true;

  //   for(let i = 0; i < openingTimesConverted.length; i++) {
  //     if(currentDate.getTime() > openingTimesConverted[i].opening.getTime()) {
  //       closed = false;
  //       if(currentDate.getTime() > openingTimesConverted[i].closing.getTime()) {
  //         closed = true
  //       }
  //       else break
  //     }
  //     else {
  //       nextOpening = openingTimesConverted[i].opening;
  //       break
  //     }
  //   }

  //   if(nextOpening) {
  //     const difference = new Date(nextOpening.getTime() - currentDate.getTime());
  //     // console.log(difference);
  //     // console.log(currentDate);
  //     // console.log(nextOpening);
  //     const remain = `${(difference.getHours() + (difference.getDate()-1)*24) -1 > 9 ? ((difference.getHours() + (difference.getDate()-1)*24) - 1):`0${difference.getHours() - 1}`}:${difference.getMinutes() > 9 ? difference.getMinutes():`0${difference.getMinutes()}`}:${difference.getSeconds() > 9 ? difference.getSeconds():`0${difference.getSeconds()}`}`
  //     // setRemainingTime(difference.getDate() > 1 ? '':remain);
  //     setRemainingTime(remain);
  //     if(remain === '00:00:01') setTimeout(() => location.reload(), 500);
  //   }
  //   else {
  //     setRemainingTime('');
  //   }
  //  }

  //  useEffect(() => {
  //   setInterval(() => calculateTime(), 1000);
  //  }, [])
  
  return (
   <div className="closed">
       <div className="closed__plants"><Image id="133d07a5-5d13-46dd-b644-7e92436fea5e" /></div>
       <h1 className="closed__title">뒤엉킨 데이터들의 정원</h1>
       {/* <p className="closed__text">
       {remainingTime !== '' && t('closed.time')}
       </p>
       <span>{remainingTime}</span> */}
   </div>
  );
};

// == Export
export default GardenClosed;
