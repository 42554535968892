import React from "react";
import { render } from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useParams,
} from "react-router-dom";

import App from "./components/App";
import Demo from "./components/Demo";
import Mobile from "./components/Mobile";
import PhotoBooth from "./components/PhotoBooth";
import { uploadSeed } from "./api";
import GardenClosed from "./components/GardenClosed";
import Error from "./components/404";
import config from "./config.json";
import './components/i18n';

const urlParams = new URLSearchParams(window.location.search);
const openParam = urlParams.get("open");
const openedWithParam = openParam !== null;

// const openingTimes = [
//   {
//     date: 21,
//     opening: 19,
//     closing: 22
//   },
//   {
//     date: 22,
//     opening: 16,
//     closing: 22
//   },
//   {
//     date: 23,
//     opening: 16,
//     closing: 22
//   },
//   {
//     date: 24,
//     opening: 11.5,
//     closing: 20.5
//   },
// ]

const dev = true;


const open = () => {
  const currentDate = new Date(new Date().getTime() + 3600000*9) ;
  // const openingTimesConverted = []

  // for(let i = 0; i < openingTimes.length; i++) {
  //   openingTimesConverted[i] = {
  //     opening: new Date(Date.UTC(2022, 8, openingTimes[i].date, openingTimes[i].opening - 2)),
  //     closing: new Date(Date.UTC(2022, 8, openingTimes[i].date, openingTimes[i].closing - 2))
  //   }
  // }

  // let nextOpening;
  let closed = true;

  // for(let i = 0; i < openingTimesConverted.length; i++) {
  //   if(currentDate.getTime() > openingTimesConverted[i].opening.getTime()) {
  //     closed = false;
  //     if(currentDate.getTime() > openingTimesConverted[i].closing.getTime()) {
  //       closed = true
  //     }
  //     else break
  //   }
  //   else {
  //     nextOpening = openingTimesConverted[i].opening;
  //   }
  // }

  if(currentDate.getMonth() === 9 && currentDate.getDate() > 29 || currentDate.getMonth() === 10 && currentDate.getDate() < 21) closed = false

  return openedWithParam || !closed;  

};

const rootComponent = (
  <Router>
    <Switch>
      <Route exact path="/">
        <Mobile />
        {open() ? <App /> : <GardenClosed />}
        {/* Add this component for enabling opening hours */}
        {/* <GardenClosed /> */}
      </Route>
      <Route exact path="/session">
        <Mobile />
        {open() ? <App /> : <GardenClosed />}
        {/* Add this component for enabling opening hours */}
        {/* <GardenClosed /> */}
      </Route>

      <Route path="/upload/:userId">
        <PhotoBooth
          uploadCallback={(data, userId) => {
            uploadSeed(data, userId, 0);
          }}
        />
      </Route>

      <Route exact path="/debug">
        <Demo></Demo>
      </Route>
      <Route path="/debug/:userId">
        <Demo></Demo>
      </Route>
      <Route path="/session/:userId">
        <Mobile />
        {open() ? <App /> : <GardenClosed />}
      </Route>
      <Route>
        <Error></Error>
      </Route>
    </Switch>
  </Router>
);

const target = document.getElementById("root");

render(rootComponent, target);
