import React, { useEffect, useState, useRef } from "react";
import { useStore } from "../../store";
import { growthStages } from "../../constants";
import Man from "./man";
import Image from "../Utils/image";
import SeedPicker from "../SeedPicker";
import Seed from "../Seed";
import Video from "../Utils/Video";
import Wrapper from "../Utils/Wrapper";
import { useTranslation } from 'react-i18next';

// == Import
import "./styles.scss";

// == Composant
const Kreuzen = ({ moveBinary }) => {
  // let copied = false;
  const { t } = useTranslation();
  const copyText1 = () => {
    if(document.querySelector(".kreuzen__text__copy__1")) {
      document.querySelector(".kreuzen__text__copy__1").style.display = "none";
      document.querySelector(".kreuzen__text__copy__1").style.animationDuration = "0s"
    }
    if(document.querySelector(".kreuzen__text__paste__2"))document.querySelector(".kreuzen__text__paste__2").style.animationDuration = "1.5s";
  };

  const pasteText1 = () => {
    if(document.querySelector(".kreuzen__text__paste__1"))document.querySelector(".kreuzen__text__paste__1").style.display = "block";
    if(document.querySelector(".kreuzen__text__paste__2"))document.querySelector(".kreuzen__text__paste__2").style.animationDuration = "0s";
  };

  const videoI = useStore((state) => state.videoI);
  const selectedSeed = useStore((state) => state.selectedSeed);
  const setSelectedSeed = useStore((state) => state.setSelectedSeed);
  const setScrollLocked = useStore((state) => state.setScrollLocked);
  const n = 30;
  const seedAmount = useStore((state) => state.seedCount);

  return (
    <section
      className="section section--kreuzen"
      id="kreuzen"
      data-0="display:none;pointer-events:none;position:fixed;top:0;left:0"
      data-152000="display:none;pointer-events:none;position:fixed;top:0;left:0"
      data-152001="display:block;pointer-events:all;position:fixed;top:0;left:0"
      data-200000="display:block;pointer-events:all;position:fixed;top:0;left:0"
      data-201501="display:none;pointer-events:none;position:fixed;top:0;left:0"
    >
      <div
        className="kreuzen__title"
        data-152000="display:none"
        data-152001="display:block"
        data-160000="display:block"
        data-161001="display:none"
      >
        <div
          className="kreuzen__title__1"
          data-155000="left:100vw;top:-50vh;position:absolute"
          data-160000="left:30vw;top:20vh;position:absolute"
        >
          <p>
          {
              Array.from(t('kreuzen.title')).map((letter, index) => {
                if(index % 2 === 0) return (
                  <span key={`${letter}--${index}`}>{letter}</span>
                )
                else return (<>{letter}</>)
              })
            }
          </p>
          <Image id="f8400af0-86b0-4469-b139-f6ea51a928f6" />
        </div>
        <div
          className="kreuzen__title__2"
          data-155000="left:-50vw;top:100vh;position:absolute"
          data-160000="left:30vw;top:20vh;position:absolute"
        >
          <p>
          {
              Array.from(t('kreuzen.title')).map((letter, index) => {
                if(index % 2 !== 0) return (
                  <span key={`${letter}--${index}`}>{letter}</span>
                )
                else return (<>{letter}</>)
              })
            }
          </p>
          <Image id="7f0bf3db-dd89-4c31-8377-c967fe4f0f39" />
        </div>
      </div>

      <div
        className="keimen__slogan1 keimen__slogan1--e"
        data-160000="left:-50vw;top:100vh"
        data-162000="left:0vw;top:50vh"
        data-164000="left:100vw;top:-100vh"
      >
        {t('kreuzen.repro')}
      </div>
      <div
        className="keimen__slogan1 keimen__slogan1--f"
        data-160000="left:-50vw;top:100vh"
        data-162000="left:0vw;top:50vh"
        data-164000="left:100vw;top:-110vh"
      >
       {t('kreuzen.repro')}
      </div>

      <div
        className="kreuzen__tag"
        data-162000="opacity:1;top:-50vh;left:20vw"
        data-163000="opacity:1;top:35vh;left:40vw"
        data-164000="opacity:1;top:45vh;left:45vw"
        data-165000="opacity:1;top:100vh;left:65vw"
      >
        <div className="kreuzen__tag__1">
        {t('kreuzen.tag1.a')}
          <br />
          <br />
          {t('kreuzen.tag1.b')}
        </div>
        <div
          className="kreuzen__tag__2"
          data-162000="margin-left:80vw"
          data-163000="margin-left:80vw"
          data-164000="margin-left:-50vw"
          data-165500="margin-left:-1000vw"
        >
          <p>{t('kreuzen.tag2.a')}</p>
          <p>{t('kreuzen.tag2.b')}</p>
        </div>
      </div>

      {/* <div className="kreuzen__picker">
          <SeedPicker
            pickedSeed={selectedSeed}
            onSeedPicked={(seed) => {
              setSelectedSeed(seed);
              console.log(seed);
            }}
          ></SeedPicker>
      </div> */}

      <div
        className="userphoto userphoto--5"
        data-164000="top:-100vh;left:-10vw"
        data-166000="top:0vh;left:0vw"
        data-170000="top:0vh;left:0vw"
        data-172000="top:100vh;left:5vw"
      >
        {/* PUT USER IMAGE 2 HERE AND REMOVE THE <img /> */}
        <Seed growthStage={growthStages.kreuzen}></Seed>

        <div className="userphoto__info userphoto__info--5" />
      </div>

      <div
        className="kreuzen__binary"
        data-171000="display:none"
        data-171001="display:block"
        data-176500="display:block"
        data-176501="display:none"
      >
        <div className="kreuzen__binary__1">&#60;{' '}{t('kreuzen.binary.a')}{' '}&#62;</div>
        <div className="kreuzen__binary__2">&#60;{' '}{t('kreuzen.binary.b')}{' '}&#62;</div>
      </div>
      <div
        className="kreuzen__geschichte"
        data-176500="display:none;opacity:0"
        data-176501="display:flex;opacity:0"
        data-177500="display:flex;opacity:1"
        data-178500="display:flex;opacity:1;top:0vh;left:0"
        data-180500="display:flex;opacity:1;top:100vh;left:10vw"
      >
       <p>
          {t('kreuzen.geschichte.a')}
          <br />
          {t('kreuzen.geschichte.b')}
          <br />
          {t('kreuzen.geschichte.c')}
        </p>
      </div>

      <div className="kreuzen__man">
        {[...Array(n)].map((e, i) => (
          <Man i={i} key={`man--${i}`} />
        ))}
      </div>

      <div
        className="kreuzen__dating"
        data-179000="display:none"
        data-179500="display:block;bottom:2vh"
        data-186500="display:block;bottom:2vh"
        data-187500="display:block;bottom:-20vh"
      >
        {t('kreuzen.dating')}
      </div>

      <div
        className="kreuzen__video"
        data-180000="display:none;top:-100vh;left:10vw"
        data-180001="display:block;top:-100vh;left:10vw"
        data-188000="display:block;top:0vh;left:0vw"
        data-195001="display:block;top:0vh;left:0vw"
        data-197000="display:block;top:100vh;left:-10vw"
        data-197001="display:none;top:100vh;left:-10vw"
      >
        <Wrapper fromStep={4} toStep={8}>
          <Video
            src={
              "https://a.storyblok.com/f/107910/x/c2f9fd4430/datadream_lowerbitrate.mp4"
            }
            poster={
              "https://a.storyblok.com/f/107910/540x500/056f5aab51/datadream.png"
            }
            playing={videoI}
            track={"/subtitles/kreuzen_datadream.vtt"}
          ></Video>
        </Wrapper>
      </div>

      <div
        className="kreuzen__text__copy"
        onClick={copyText1}
        data-195000="display:none"
        data-195001="display:block;top:20vh;left:10vw"
        data-198001="display:block;top:50vh;left:10vw"
        data-201001="display:block;top:100vh;left:-20vw"
      >
         <h3>{t('kreuzen.copy')}</h3>
        <p className="kreuzen__text__copy__1">
          {
            t('kreuzen.cp_text.a').split(' ').map((text, index) => {
              if(index % 2 === 0) return (
                <span>{' '+text+' '}</span>
              )
              else return (text)
            })
          }
          <br />
          <br />
          {
            t('kreuzen.cp_text.b').split(' ').map((text, index) => {
              if(index % 2 === 0) return (
                <span>{' '+text+' '}</span>
              )
              else return (text)
            })
          }
        </p>
      </div>

      <div
        className="kreuzen__text__paste"
        onClick={pasteText1}
        data-195000="display:none"
        data-195001="display:block;top:-20vh;left:100vw"
        data-199001="display:block;top:40vh;left:40vw"
        data-201001="display:block;top:100vh;left:20vw"
      >
        <h3>{t('kreuzen.paste')}</h3>
        <p className="kreuzen__text__paste__1">
        {
            t('kreuzen.cp_text.a').split(' ').map((text, index) => {
              if(index % 2 === 0) return (
                <span>{' '+text+' '}</span>
              )
              else return (text)
            })
          }
          <br />
          <br />
          {
            t('kreuzen.cp_text.b').split(' ').map((text, index) => {
              if(index % 2 === 0) return (
                <span>{' '+text+' '}</span>
              )
              else return (text)
            })
          }
        </p>
        <p className="kreuzen__text__paste__2">
        {
            t('kreuzen.cp_text.a').split(' ').map((text, index) => {
              if(index % 2 === 0) return (
                <span>{' '+text+' '}</span>
              )
              else return (text)
            })
          }
          <br />
          <br />
          {
            t('kreuzen.cp_text.b').split(' ').map((text, index) => {
              if(index % 2 === 0) return (
                <span>{' '+text+' '}</span>
              )
              else return (text)
            })
          }
        </p>
      </div>
      <div
        className="kreuzen__copy__pfropfen"
        data-199100="top:-20vh;left:80vw"
        data-201000="top:80vh;left:50vw"
      >
        {t('kreuzen.pfropfen')}
      </div>
      <div
        className="kreuzen__garden"
        data-198000="opacity:0"
        data-199000="opacity:1"
        data-201000="opacity:0"
      >
        {t('kreuzen.garden.a')}<br/>{t('kreuzen.garden.b')}
      </div>

      <div className="kreuzen__floater kreuzen__floater--1"
        data-0="left:-20vw;top:50vh;"
        data-171000="left:-40vw;top:30vh;opacity:0"
        data-171100="left:-40vw;top:30vh;opacity:1"
        data-176000="top:50vw;left:140vh;opacity:1"
        data-176500="top:50vw;left:140vh;opacity:0"
      >
        {/* FILLES NB */}
        <Image id="759d804c-b323-4f72-80c5-b3f2d67ea1ee" />
      </div>
      <div className="kreuzen__floater kreuzen__floater--2"
        data-0="opacity:0;display:block"
        data-180400="opacity:0;"
        data-180600="opacity:1;"
        data-187000="opacity:1;"
        data-188000="opacity:0;display:block"
        data-188001="display:none"
      >
        {/* BOXES GIF */}
        <img src="https://a.storyblok.com/f/107910/700x700/addaa44396/boxessmall.png" />
      </div>
      {/* <div className="kreuzen__floater kreuzen__floater--3"
        data-0="left:40vw;top:-30vh;"
        data-146000="left:40vw;top:-30vh;"
        data-148000="left:20vw;top:100vh;"
      >
        <Image id="d07b3c14-585f-4e1b-8435-19db8040e387" />
      </div> */}
      <div className="kreuzen__floater kreuzen__floater--4"
        data-0="left:0vw;top:100vh;opacity:0"
        data-171000="left:0vw;top:100vh;opacity:0"
        data-171500="left:0vw;top:100vh;opacity:1"
        data-176000="left:100vw;top:20vh;opacity:1"
        data-176500="left:100vw;top:20vh;opacity:0"
      >
        {/* MACHINE */}
        <Image id="5d588bfb-3e01-40b1-a9b2-2b6fe3d956fc" />
      </div>
      <div className="kreuzen__floater kreuzen__floater--5"
        data-0="left:60vw;top:-30vh;opacity:0"
        data-171000="left:60vw;top:-30vh;opacity:0"
        data-172000="left:60vw;top:-30vh;opacity:1"
        data-176000="left:30vw;top:100vh;opacity:1"
        data-176200="left:30vw;top:100vh;opacity:0"
      >
        {/* LEAF FRAME */}
        <Image id="92a7d415-38af-4ca1-8fcc-1c72d3bfdaee" />
      </div>
      <div className="kreuzen__floater kreuzen__floater--6"
        data-0="left:-20vw;top:20vh;"
        data-161000="left:-20vw;top:20vh;"
        data-165000="left:100vw;top:65vh;"
      >
        {/* FRUIT 1 */}
        <Image id="d3bf5e45-78e3-438f-922f-b8d6acd7fade" />
      </div>
      <div className="kreuzen__floater kreuzen__floater--7"
        data-0="left:20vw;top:-30vh;"
        data-180000="left:20vw;top:-30vh;"
        data-185000="left:90vw;top:100vh;"
      >
        {/* FRUIT 2 */}
        <Image id="d3bf5e45-78e3-438f-922f-b8d6acd7fade" />
      </div>
    </section>
  );
};

// == Export
export default Kreuzen;
