import React, { useEffect, useState } from "react";
import $ from "jquery";
import { useStore } from "../../store";
import Image from '../Utils/image'
import { useTranslation } from 'react-i18next';

// == Import
import "./styles.scss";

// == Composant
const Mobile = () => {
  const { t } = useTranslation();
  return (
   <div className="mobile">
       <div className="mobile__plants" ><Image id="d589e744-92f4-4617-b8c6-7710fde86d98" /></div>
       <h1 className="mobile__title">{t('mobile.a')}<br/>{t('mobile.b')}</h1>
       <p className="mobile__text">
       {t('mobile.c')}
        <span>{t('mobile.e')}</span>
       </p>
   </div>
  );
};

// == Export
export default Mobile;
