import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';
import config from "../config";

const Container = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 25px;
  border: 2px solid #73ad21;
  padding: 20px;
  background-color: white;
  color: black;
`;

export default function VisitorCounter() {
  const { t } = useTranslation();
  const [connections, setConnections] = useState(0);
  useEffect(() => {
    const client = new WebSocket(config.wss);
    client.onmessage = (event) => {
      try {
        const action = JSON.parse(event.data);
        // console.log(action, event.data);
        if (action.type === "CONNECTIONEVENT") {
          setConnections(action.payload.value);
        }
      } catch (error) {}
    };
    return () => {
      client.close();
    };
  }, [setConnections]);
  return (
    <>
      <Container>
        {connections} {t('people')}
      </Container>
    </>
  );
}
