import React from "react";
import styled from "styled-components";
import { useStore } from "../../store";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function Wrapper({ fromStep, toStep = 10, children }) {
  const currentStep = useStore((state) => state.currentStep);
  return (
    <Container>{fromStep <= currentStep && currentStep <= toStep && <>{children}</>}</Container>
  );
}
