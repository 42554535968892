import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import styled from "styled-components";
import config from "../config";
import { format, differenceInMilliseconds } from "date-fns";
import { useStore } from "../store";

const Container = styled.div`
  border: 2px solid #73ad21;
  padding: 20px;
  background-color: black;
  color: #33ff33;
`;

let timeoutId;
export default function UploadListener({ onUpload }) {
  const { t } = useTranslation();
  const userId = useStore((state) => state.userId);
  const [userUploaded, setUserUploaded] = useState("");
  const [userUploadedTimestamp, setUserUploadedTimestamp] = useState(null);
  useEffect(() => {
    const client = new WebSocket(config.wss);
    client.onmessage = (event) => {
      try {
        const action = JSON.parse(event.data);
        if (action.type === "PHOTOUPLOADED") {
          setUserUploaded(action.payload.userId);
          setUserUploadedTimestamp(new Date());
          clearTimeout(timeoutId);
          setTimeout(() => {
            timeoutId = setUserUploadedTimestamp(null);
          }, 10000);
          onUpload(action.payload.userId);
        }
      } catch (error) {}
    };
    return () => {
      client.close();
    };
  }, [setUserUploaded]);
  return (
    <>
      <Container>
        {t('photo.id')}: {userId} <br></br>
        {/* user uploaded: {userUploaded} */}
        {userUploaded !== userId && userUploadedTimestamp && (
          <>
            {t('photo.another')}:{" "}
            {format(userUploadedTimestamp, "H:mm:ss")}
          </>
        )}
        {userUploaded === userId && (
          <>
            {" "}
            {t('photo.successfully')}{" "}
            {/* {format(userUploadedTimestamp, "H:mm:ss")} */}
          </>
        )}
      </Container>
    </>
  );
}
