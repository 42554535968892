import React from "react";
import styled from "styled-components";
import config from "../config.json";
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100000;
`;

export default function Restart() {

  const { t } = useTranslation();

  return (
    <Container>
      <a href={config.url} target="_blank">
        {t('restart')}
      </a>
    </Container>
  );
}
