import React, { useState } from "react";
import styled from "styled-components";
import config from "../config.json";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  margin: 0;
  background-color: white;
  color: black;
  width: 100vw;
  height: 100vh;
  padding: 50px;
  h1 {
    margin-top: 0;
    color: black;
  }
  ul {
    margin-top: 100px;
  }
`;

export default function () {
  const [userId, setUserId] = useState("");
  const { t } = useTranslation();
  return (
    <Container>
      <h1>{t('404.title')}</h1>
      <p>{t('404.lost')}</p>
      <p>{t('404.id')}</p>
      <TextField
        value={userId}
        placeholder={"userId, e.g. GOTD"}
        onChange={(e) => {
          setUserId(e.target.value);
        }}
      ></TextField>
      <ul>
        <li>
        {t('404.main')}{" "}
          <a href={`${config.url}/${userId ? userId : ""}`}>{t('404.here')}</a>
        </li>
        {userId !== "" && (
          <li>
            {t('404.upload')}{" "}
            <a href={`${config.uploadUrl}/${userId ? userId : ""}`}>{t('404.here')}</a>
          </li>
        )}
      </ul>
    </Container>
  );
}
