import React, { useEffect, useState, useRef } from "react";
import { useStore } from "../../store";
// import P5 from "../effects/Verzweigen";
import { getAssetUrl, uploadSeed } from "../../api";
import { growthStages } from "../../constants";
import Image from "../Utils/image";
import Seed from "../Seed";
import Video from "../Utils/Video";
import Wrapper from "../Utils/Wrapper";
import { useTranslation } from 'react-i18next';

// == Import
import "./styles.scss";
import "./circles.scss";

// == Composant
const Verzweigen = ({ branchingPic }) => {
  const { t } = useTranslation();
  const devMode = useStore((state) => state.devMode);
  const userId = useStore((state) => state.userId);
  const growthStage = useStore((state) => state.growthStage);
  const seed = useStore((state) => state.seed);
  const videoE = useStore((state) => state.videoE);
  const videoF = useStore((state) => state.videoF);

  const seedAmount = useStore((state) => state.seedCount);

  useEffect(() => {
    // console.log('BRANCHING PIC: '+branchingPic);
  }, [branchingPic]);

  // const removeGif = (target) => {
  //   target.remove();
  // };

  // const videoRefs = [useRef(null), useRef(null)];

  // useEffect(() => {
  //   if (videoE) {
  //     videoRefs[0].current.play();
  //     videoRefs[0].current.muted = false;
  //   } else {
  //     videoRefs[0].current.pause();
  //   }
  // }, [videoE]);
  // useEffect(() => {
  //   if (videoF) {
  //     videoRefs[1].current.play();
  //     videoRefs[1].current.muted = false;
  //   } else {
  //     videoRefs[1].current.pause();
  //   }
  // }, [videoF]);

  // const gifs1List = [];
  // for (var icont = 100; icont < 107; icont++) {
  //   for (var i = 0; i < 15; i++) {
  //     gifs1List.push(
  //       <div key={`${icont}_${i}`}>
  //         <img
  //           className={`gif1__block gif1__block--${i} gif1__block--${icont}`}
  //           src="https://a.storyblok.com/f/107910/312x236/f5ae529580/1-ahtqzgpicekrpwp1re0hng.gif"
  //           data-90100="opacity:0;left:100vw;top:-15vh"
  //           data-91100="opacity:1;left:100vw;top:100vh"
  //           data-92100="opacity:1;left:90vw;top:85vh"
  //           data-95100={`opacity:1;left:${100 - i * 10}vw;top:0vh`}
  //           onMouseEnter={(event) => removeGif(event.target)}
  //           key={`a_{i}`}
  //         />
  //         <img
  //           className={`gif2__block gif2__block--${i} gif2__block--${icont}`}
  //           src="https://a.storyblok.com/f/107910/466x354/f370ac82dc/firstcomputer.gif"
  //           data-90100="opacity:0;left:100vw;top:-15vh"
  //           data-91100="opacity:1;left:100vw;top:100vh"
  //           data-92100="opacity:1;left:90vw;top:85vh"
  //           data-95100={`opacity:1;left:${100 - i * 10}vw;top:0vh`}
  //           onMouseEnter={(event) => removeGif(event.target)}
  //           key={`b_{i}`}
  //         />
  //       </div>
  //     );
  //   }
  // }

  return (
    <section
      className="section section--verzweigen"
      id="verzweigen"
      data-0="display:none;pointer-events:none;position:fixed;top:0;left:0"
      data-65000="display:none;pointer-events:none;position:fixed;top:0;left:0"
      data-65001="display:block;pointer-events:all;position:fixed;top:0;left:0"
      data-110000="display:block;pointer-events:all;position:fixed;top:0;left:0"
      data-110001="display:none;pointer-events:none;position:fixed;top:0;left:0"
    >
      {/* <div
        className="verzweigen__background"
        data-0="opacity:0"
        data-65000="opacity:0"
        data-68000="opacity:1;left:-50vw;top:-50vh;height:200vh;width:200vw"
        data-65001="opacity:1;left:0vw;top:0vh;height:100vh;width:100vw"
        data-69000="opacity:0.1"
        data-72000="opacity:0"
      >
        <Image id="f56109fa-35dd-4270-9352-92ba52d2e6b3" />
      </div> */}
      <div
        className="verzweigen__title"
        data-67000="opacity:0"
        data-68000="opacity:1"
        data-70000="opacity:1"
        data-71000="opacity:0"
      >
         {t('verzweigen.title')}
      </div>
      <div
        className="verzweigen__text1"
        data-70000="display:block"
        data-70100="left:100vw;top:0vw"
        data-71000="left:0vw;top:0vw"
        data-71100="left:0vw;top:-7vw"
        data-72000="left:-100vw;top:-7vw"
        data-72100="left:-100vw;top:-14vw"
        data-73000="left:-200vw;top:-14vw"
        data-73100="left:-200vw;top:-21vw"
        data-74000="left:-300vw;top:-21vw"
        data-74100="display:block"
        data-74200="display:none"
      >
       <p className="verzweigen__text1__a">{t('verzweigen.text1.a')}</p>
        <p className="verzweigen__text1__b">
        {t('verzweigen.text1.b')}
        </p>
        <p className="verzweigen__text1__c">{t('verzweigen.text1.c')}</p>
        <p className="verzweigen__text1__d">{t('verzweigen.text1.d')}</p>
      </div>
      <div
        className="verzweigen__video1"
        data-72000="display:none"
        data-72001="display:block;left:-20vw;top:100vh"
        data-74000="display:block;left:0vw;top:0vh"
        data-92000="display:block;left:0vw;top:0vh"
        data-92001="display:none"
      >
        <Wrapper fromStep={2} toStep={8}>
          <Video
            src={
              "https://a.storyblok.com/f/107910/x/3271630f0c/verzweigung_1280.mp4"
            }
            poster={
              "https://a.storyblok.com/f/107910/892x500/51e95b36b1/kreuzen.png"
            }
            playing={videoE}
            track={"/subtitles/verzweigung_organism_final.vtt"}
          ></Video>
        </Wrapper>
      </div>

      <div
        className="verzweigen__text2"
        data-0="display:none"
        data-86500="display:flex;opacity:0;font-size:12vh;-webkit-text-stroke: 1px black;left: 0vw"
        data-87000="opacity:1;font-size:12vh;-webkit-text-stroke: 1px black;left: 0vw"
        data-90000="opacity:1;font-size:12vh;-webkit-text-stroke: 1px black;left: 0vw"
        data-92000="opacity:1;font-size:300vh;-webkit-text-stroke: 10px black;left: -100vw"
        data-92400="opacity:1"
        data-93000="opacity:0;display:none"
      >
        <div className="verzweigen__text2__container">
          <p
            data-87000="width:0%;position:relative"
            data-87500="width:100%;position:relative"
          >
            {t('verzweigen.text2.a')}
          </p>
          <p
            data-87200="width:0%;position:relative"
            data-87700="width:100%;position:relative"
          >
            {t('verzweigen.text2.b')}
          </p>
          <p
            data-87400="width:0%;position:relative"
            data-87900="width:100%;position:relative"
          >
            {t('verzweigen.text2.c')}
          </p>
          <p
            data-87600="width:0%;position:relative"
            data-88100="width:100%;position:relative"
          >
            {t('verzweigen.text2.d')}
          </p>
          <p
            data-87800="width:0%;position:relative"
            data-88300="width:100%;position:relative"
          >
            {t('verzweigen.text2.e')}
          </p>
          <p
            data-88000="width:0%;position:relative"
            data-88400="width:100%;position:relative"
          >
            {t('verzweigen.text2.f')}
          </p>
        </div>
      </div>

      <div
        className="userphoto userphoto--3"
        data-82000="left:100vw"
        data-83000="left:0vw"
        data-84000="left:0vw"
        data-92000="left:0vw"
        data-93000="left:100vw"
      >
        <div className="userphoto__info userphoto__info--3" />
        {/* PUT USER IMAGE 3 HERE AND REMOVE THE <img /> */}
        <Seed growthStage={growthStages.verzweigen} debug={false}></Seed>
      </div>

      <div
        className="verzweigen__video2"
        data-91000="display:none;"
        data-92000="display:block;left:0vw;top:0vh"
        data-105100="display:block;left:0vw;top:0vh"
        data-107000="display:block;left:100vw;top:-30vh"
        data-107100="display:none;"
      >
        <Wrapper fromStep={1} toStep={8}>
          <Video
            src={
              "https://a.storyblok.com/f/107910/x/c0c5dbd8ef/databreaths_shortlobitrate.mp4"
            }
            poster={
              "https://a.storyblok.com/f/107910/887x500/995f97829e/databreath.png"
            }
            track={"/subtitles/afterverzweigung.vtt"}
            playing={videoF}
          ></Video>
        </Wrapper>
      </div>

      <div
        className="verzweigen__text3"
        data-107000="opacity:0;display:block"
        data-108000="opacity:1;"
        data-109900="opacity:1;"
        data-110000="opacity:0;display:block"
        data-110001="display:none"
      >
        {t('verzweigen.text3.a')}
        <br />
        <span className="verzweigen__text3__w verzweigen__text3__welt">
        {t('verzweigen.text3.b')}
        </span>
        <span className="verzweigen__text3__w verzweigen__text3__wald">
        {t('verzweigen.text3.c')}
        </span>
        <br />
        {t('verzweigen.text3.d')}
        <span id="verzweigen__all">{t('verzweigen.text3.e')}</span>
        <span id="verzweigen__algo">{t('verzweigen.text3.f')}</span>
      </div>
      <div
        className="verzweigen__gif1"
        data-106000="display:none"
        data-106001="display:block"
        data-109000="display:block"
        data-109100="display:none"
      >
        <img
          data-106001="margin-left:200px"
          data-107001="margin-left:0px"
          className="gif1__block gif1__block--1"
          src="https://a.storyblok.com/f/107910/312x236/f5ae529580/1-ahtqzgpicekrpwp1re0hng.gif"
        />
        <img
          data-106001="margin-left:-200px"
          data-107001="margin-left:0px"
          className="gif1__block gif1__block--2"
          src="https://a.storyblok.com/f/107910/466x354/f370ac82dc/firstcomputer.gif"
        />
      </div>
      <div
        className="verzweigen__branching"
        data-65000="opacity:0;"
        data-66000="opacity:1;"
        data-74000="opacity:1;"
        data-75000="opacity:0;"
      >
          <img src="https://a.storyblok.com/f/107910/800x800/08c06e0577/branchinganimamtionlores.png" />
  
      </div>

      <div
        className="verzweigen__slogan verzweigen__slogan--1"
        data-68000="display:none"
        data-68500="display:block"
        data-67000="opacity:1"
        data-74000="opacity:1"
        data-74200="opacity:0.2"
        data-79200="opacity:0.2"
        data-80000="opacity:0"
      >
        <div className="slogan__circle">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="300px"
            height="300px"
            viewBox="0 0 300 300"
            enableBackground="new 0 0 300 300"
            xmlSpace="preserve"
          >
            <defs>
              <path
                id="circlePath1"
                d="M 150, 150 m -60, 0 a 60,60 0 0,1 120,0 a 60,60 0 0,1 -120,0 "
              />
            </defs>
            <circle cx="150" cy="100" r="75" fill="none" />
            <g>
              <use xlinkHref="#circlePath1" fill="none" />
              <text fill="white">
                <textPath xlinkHref="#circlePath1">
                {t('verzweigen.slogans.a')}
                </textPath>
              </text>
            </g>
          </svg>
        </div>
      </div>
      <div
        className="verzweigen__slogan verzweigen__slogan--2"
        data-68000="display:none"
        data-68800="display:block"
        data-67000="opacity:1"
        data-74000="opacity:1"
        data-74200="opacity:0.2"
        data-79200="opacity:0.2"
        data-80000="opacity:0"
      >
        <div className="slogan__circle">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="300px"
            height="300px"
            viewBox="0 0 300 300"
            enableBackground="new 0 0 300 300"
            xmlSpace="preserve"
          >
            <defs>
              <path
                id="circlePath2"
                d="M 150, 150 m -60, 0 a 60,60 0 0,1 120,0 a 60,60 0 0,1 -120,0 "
              />
            </defs>
            <circle cx="150" cy="100" r="75" fill="none" />
            <g>
              <use xlinkHref="#circlePath2" fill="none" />
              <text fill="white">
                <textPath xlinkHref="#circlePath2">
                {t('verzweigen.slogans.b')}
                </textPath>
              </text>
            </g>
          </svg>
        </div>
      </div>
      <div
        className="verzweigen__slogan verzweigen__slogan--3"
        data-68000="display:none"
        data-69000="display:block"
        data-67000="opacity:1"
        data-74000="opacity:1"
        data-74200="opacity:0.2"
        data-79200="opacity:0.2"
        data-80000="opacity:0"
      >
        <div className="slogan__circle">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="300px"
            height="300px"
            viewBox="0 0 300 300"
            enableBackground="new 0 0 300 300"
            xmlSpace="preserve"
          >
            <defs>
              <path
                id="circlePath3"
                d="M 150, 150 m -60, 0 a 60,60 0 0,1 120,0 a 60,60 0 0,1 -120,0 "
              />
            </defs>
            <circle cx="150" cy="100" r="75" fill="none" />
            <g>
              <use xlinkHref="#circlePath3" fill="none" />
              <text fill="white">
                <textPath xlinkHref="#circlePath3">
                {t('verzweigen.slogans.c')}
                </textPath>
              </text>
            </g>
          </svg>
        </div>
      </div>
      <div
        className="verzweigen__slogan verzweigen__slogan--4"
        data-67000="opacity:1"
        data-68000="display:none"
        data-69200="display:block"
        data-74000="opacity:1"
        data-74200="opacity:0.2"
        data-79200="opacity:0.2"
        data-80000="opacity:0"
      >
        <div className="slogan__circle">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="300px"
            height="300px"
            viewBox="0 0 300 300"
            enableBackground="new 0 0 300 300"
            xmlSpace="preserve"
          >
            <defs>
              <path
                id="circlePath4"
                d="M 150, 150 m -60, 0 a 60,60 0 0,1 120,0 a 60,60 0 0,1 -120,0 "
                onMouseOver={(event) => drawLines(event)}
              />
            </defs>
            <circle cx="150" cy="100" r="75" fill="none" />
            <g>
              <use xlinkHref="#circlePath4" fill="none" />
              <text fill="white">
                <textPath xlinkHref="#circlePath4">
                {t('verzweigen.slogans.d')}
                </textPath>
              </text>
            </g>
          </svg>
        </div>
      </div>
      <div
        className="verzweigen__lines"
        data-68000="display:none;opacity:1;"
        data-69200="display:block;opacity:1;"
        data-80000="opacity:0;display:none"
      >
        <div
          className="verzweigen__hovercircles__1"
          onMouseOver={(event) => {
            if(document.querySelector(`#circleaudio--1`)) document.querySelector(`#circleaudio--1`).play();
            // console.log("CRIC");
          }}
        />
        <div
          className="verzweigen__hovercircles__2"
          onMouseOver={(event) => {
            if(document.querySelector(`#circleaudio--2`)) document.querySelector(`#circleaudio--2`).play();
            // console.log("CRIC");
          }}
        />
        <div
          className="verzweigen__hovercircles__3"
          onMouseOver={(event) => {
            if(document.querySelector(`#circleaudio--3`)) document.querySelector(`#circleaudio--3`).play();
            // console.log("CRIC");
          }}
        />
        <div
          className="verzweigen__hovercircles__4"
          onMouseOver={(event) => {
            if(document.querySelector(`#circleaudio--4`)) document.querySelector(`#circleaudio--4`).play();
            // console.log("CRIC");
          }}
        />
        <div className="verzweigen__lines__1 verzweigen__lines__container">
          <div className="verzweigen__lines__1__a" />
          <div className="verzweigen__lines__1__b" />
          <div className="verzweigen__lines__1__c" />
        </div>
        <div className="verzweigen__lines__2 verzweigen__lines__container">
          <div className="verzweigen__lines__2__a" />
          <div className="verzweigen__lines__2__b" />
          <div className="verzweigen__lines__2__c" />
        </div>
        <div className="verzweigen__lines__3 verzweigen__lines__container">
          <div className="verzweigen__lines__3__a" />
          <div className="verzweigen__lines__3__b" />
          <div className="verzweigen__lines__3__c" />
        </div>
        <div className="verzweigen__lines__4 verzweigen__lines__container">
          <div className="verzweigen__lines__4__a" />
          <div className="verzweigen__lines__4__b" />
          <div className="verzweigen__lines__4__c" />
        </div>
      </div>

      {/* <div className="verzweigen__lines"
            data-10500="display:block"
        >
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="100vw" height="100vh" xmlSpace="preserve">
                <path id="line1" d="M 45 726 l 354 -0.8" stroke="red" strokeWidth="1" fill="none" />
            </svg>
        </div> */}

      <div
        className="verzweigen__floater verzweigen__floater--1"
        data-0="left:20vw;top:100vh"
        data-71000="left:20vw;top:100vh"
        data-74000="left:100vw;top:30vh"
      >
        {/* QR */}
        <Image id="2cbdd0ae-ddb4-4a80-95ea-a952008f2021" />
      </div>
      <div
        className="verzweigen__floater verzweigen__floater--2"
        data-0="left:-50vw;top:100vh"
        data-69000="left:-50vw;top:100vh"
        data-71000="left:150vw;top:-10vh"
      >
        {/* PHONE BRANCH */}
        <Image id="a1e53657-259d-4581-8357-9bf105f3b497" />
      </div>
      <div
        className="verzweigen__floater verzweigen__floater--3"
        data-0="left:100vw;top:40vh"
        data-96000="left:100vw;top:40vh"
        data-102000="left:-40vw;top:20vh"
      >
        {/* BRANCH */}
        <Image id="a27fdd34-8feb-4add-9258-004c2dcf7f2a" />
      </div>
      <div
        className="verzweigen__floater verzweigen__floater--4"
        data-0="left:20vw;top:100vh"
        data-107000="left:20vw;top:100vh"
        data-109000="left:75vw;top:-130vh"
      >
        {/* N ET BLANC */}
        <Image id="34e0e871-eb04-4640-9ff6-dd4e6a7ced14" />
      </div>
      <div
        className="verzweigen__floater verzweigen__floater--5"
        data-0="left:-30vw;top:40vh"
        data-93500="left:-30vw;top:40vh"
        data-96000="left:70vw;top:-40vh"
      >
        {/* PHONE */}
        <Image id="c712c8b5-0d4c-42b9-a961-671e97da8b0e" />
      </div>
    </section>
  );
};

// == Export
export default Verzweigen;
