import React, { useEffect, useRef } from "react";
import './video.css';
// import styled from "styled-components";
// const Container = styled.div``;

// TODO: would it make sense to use react-player, or any other smart video player
export default function Video({
  src,
  poster,
  playing,
  track
}) {
  const ref = useRef(null);
  useEffect(() => {
    if (playing) {
      ref.current.play();
      ref.current.muted = false;
    } else {
      ref.current.pause();
    }
    ref.current.onended = function() {
      ref.current.load();
    };
  }, [playing]);



  return (
    <div className="video-wrapper">
      <div className="video-container">
        <video ref={ref} poster={poster} preload="auto">
          <source src={src}></source>
          {/* Disabled  subtitles for German */}
          {track && <track src={track} kind="subtitles" srcLang="ko" label="Korean" default />}
        </video>
      </div>
    </div>
  );
}
